@import '../../assets/styles/custom';

@-webkit-keyframes sm-loadingIndicator-slide {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
}
@keyframes sm-loadingIndicator-slide {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(200%);
    transform: translateX(200%);
  }
}
@-webkit-keyframes sm-loadingIndicator-slideToCenter {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}
@keyframes sm-loadingIndicator-slideToCenter {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}
@-webkit-keyframes sm-loadingIndicator-expand {
  0% {
    width: 100%;
    left: 0%;
  }
  50% {
    background: $primary;
    box-shadow: 0 1px calc(4px + 1px) rgba($primary, 0.5);
  }
  100% {
    background: $danger;
    box-shadow: 0 1px calc(4px + 1px) rgba($danger, 0.5);
    left: -55%;
    width: 210%;
  }
}
@keyframes sm-loadingIndicator-expand {
  0% {
    width: 100%;
    left: 0%;
  }
  50% {
    background: $primary;
    box-shadow: 0 1px calc(4px + 1px) rgba($primary, 0.5);
  }
  100% {
    background: $danger;
    box-shadow: 0 1px calc(4px + 1px) rgba($danger, 0.5);
    left: -55%;
    width: 210%;
  }
}
/* common (fast and slow) */
.sm-loadingIndicator {
  top: 0;
  left: 0;
  right: 0;
  /* Increase z-index to allow stacking on top of overlays, action sheets, etc. */
  z-index: 10;
}
.sm-loadingIndicator-stripe {
  height: 4px;
  border-radius: 4px;
  background: $primary;
  box-shadow: 0 1px calc(4px + 1px) rgba($primary, 0.5);
  transition: background 0.25s ease, box-shadow 0.25s ease;
}
/* slow */
.sm-loadingIndicatorStatus--slow {
  width: 50%;
  overflow: visible;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.sm-loadingIndicatorStatus--slow.sm-loadingIndicatorStatus--working {
  -webkit-animation: 1.5s cubic-bezier(0.75, 0, 0.5, 1) infinite
    sm-loadingIndicator-slide;
  animation: 1.5s cubic-bezier(0.75, 0, 0.5, 1) infinite
    sm-loadingIndicator-slide;
}
@media (min-width: 768px) {
  .sm-loadingIndicatorStatus--slow.sm-loadingIndicatorStatus--working {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
}
@media (min-width: 1024px) {
  .sm-loadingIndicatorStatus--slow.sm-loadingIndicatorStatus--working {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
  }
}

.sm-loadingIndicatorStatus--fast {
  width: 100%;
  margin: auto;
  overflow: visible;
  -webkit-transform: scaleX(0.9);
  transform: scaleX(0.9);
}
.sm-loadingIndicatorStatus--fast .sm-loadingIndicator-stripe {
  background: $danger;
  box-shadow: 0 1px calc(4px + 1px) rgba($danger, 0.5);
}
.sm-loadingIndicatorStatus--fast {
  transition: -webkit-transform ease 0.5s;
  transition: transform ease 0.5s;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
  -webkit-transform: scaleX(1.1);
  transform: scaleX(1.1);
  -webkit-transform-origin: center;
  transform-origin: center;
}
/* fade */
.sm-loadingIndicatorStatus--fade.sm-loadingIndicatorStatus--slow
  .sm-loadingIndicator-stripe,
.sm-loadingIndicatorStatus--fade.sm-loadingIndicatorStatus--fast
  .sm-loadingIndicator-stripe {
  -webkit-animation: none;
  animation: none;
  background: rgba(231, 91, 82, 0);
  box-shadow: 0 1px calc(4px + 1px) rgba(231, 91, 82, 0);
}
