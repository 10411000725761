.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.fixed {
  position: fixed !important;
}
.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.absoluteCenter-x {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.absoluteCenter-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.absoluteSpread {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
