html {
  font-size: 16px; }

strong,
b {
  font-weight: 600; }

[data-focus-source='keyboard'] :focus {
  border-color: transparent;
  outline: 2px solid #008248; }

.flex {
  display: flex !important; }

.flex-column {
  flex-direction: column !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.items-start {
  align-items: flex-start !important; }

.items-end {
  align-items: flex-end !important; }

.items-center {
  align-items: center !important; }

.items-baseline {
  align-items: baseline !important; }

.items-stretch {
  align-items: stretch !important; }

.self-start {
  align-self: flex-start !important; }

.self-end {
  align-self: flex-end !important; }

.self-center {
  align-self: center !important; }

.self-baseline {
  align-self: baseline !important; }

.self-stretch {
  align-self: stretch !important; }

.justify-start {
  justify-content: flex-start !important; }

.justify-end {
  justify-content: flex-end !important; }

.justify-center {
  justify-content: center !important; }

.justify-between {
  justify-content: space-between !important; }

.justify-around {
  justify-content: space-around !important; }

.content-start {
  align-content: flex-start !important; }

.content-end {
  align-content: flex-end !important; }

.content-center {
  align-content: center !important; }

.content-between {
  align-content: space-between !important; }

.content-around {
  align-content: space-around !important; }

.content-stretch {
  align-content: stretch !important; }

.flex-1 {
  flex: 1 !important; }

.flex-none {
  flex: none !important; }

.flex-shrink {
  flex-shrink: 1 !important; }

.flex-shrink-none {
  flex-shrink: 0 !important; }

.flex-grow {
  flex-grow: 1 !important; }

.flex-grow-none {
  flex-grow: 0 !important; }

.flex-basis-none {
  flex-basis: 0% !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-last {
  order: 99999 !important; }

.order-first {
  order: -1 !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.px0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p1 {
  padding: 0.4rem !important; }

.pt1 {
  padding-top: 0.4rem !important; }

.pr1 {
  padding-right: 0.4rem !important; }

.pb1 {
  padding-bottom: 0.4rem !important; }

.pl1 {
  padding-left: 0.4rem !important; }

.px1 {
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important; }

.py1 {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important; }

.p2 {
  padding: 0.8rem !important; }

.pt2 {
  padding-top: 0.8rem !important; }

.pr2 {
  padding-right: 0.8rem !important; }

.pb2 {
  padding-bottom: 0.8rem !important; }

.pl2 {
  padding-left: 0.8rem !important; }

.px2 {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important; }

.py2 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important; }

.p3 {
  padding: 1.6rem !important; }

.pt3 {
  padding-top: 1.6rem !important; }

.pr3 {
  padding-right: 1.6rem !important; }

.pb3 {
  padding-bottom: 1.6rem !important; }

.pl3 {
  padding-left: 1.6rem !important; }

.px3 {
  padding-left: 1.6rem !important;
  padding-right: 1.6rem !important; }

.py3 {
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important; }

.p4 {
  padding: 2.4rem !important; }

.pt4 {
  padding-top: 2.4rem !important; }

.pr4 {
  padding-right: 2.4rem !important; }

.pb4 {
  padding-bottom: 2.4rem !important; }

.pl4 {
  padding-left: 2.4rem !important; }

.px4 {
  padding-left: 2.4rem !important;
  padding-right: 2.4rem !important; }

.py4 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mx0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m1 {
  margin: 0.4rem !important; }

.mt1 {
  margin-top: 0.4rem !important; }

.mr1 {
  margin-right: 0.4rem !important; }

.mb1 {
  margin-bottom: 0.4rem !important; }

.ml1 {
  margin-left: 0.4rem !important; }

.mx1 {
  margin-left: 0.4rem !important;
  margin-right: 0.4rem !important; }

.my1 {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important; }

.m2 {
  margin: 0.8rem !important; }

.mt2 {
  margin-top: 0.8rem !important; }

.mr2 {
  margin-right: 0.8rem !important; }

.mb2 {
  margin-bottom: 0.8rem !important; }

.ml2 {
  margin-left: 0.8rem !important; }

.mx2 {
  margin-left: 0.8rem !important;
  margin-right: 0.8rem !important; }

.my2 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important; }

.m3 {
  margin: 1.6rem !important; }

.mt3 {
  margin-top: 1.6rem !important; }

.mr3 {
  margin-right: 1.6rem !important; }

.mb3 {
  margin-bottom: 1.6rem !important; }

.ml3 {
  margin-left: 1.6rem !important; }

.mx3 {
  margin-left: 1.6rem !important;
  margin-right: 1.6rem !important; }

.my3 {
  margin-top: 1.6rem !important;
  margin-bottom: 1.6rem !important; }

.m4 {
  margin: 2.4rem !important; }

.mt4 {
  margin-top: 2.4rem !important; }

.mr4 {
  margin-right: 2.4rem !important; }

.mb4 {
  margin-bottom: 2.4rem !important; }

.ml4 {
  margin-left: 2.4rem !important; }

.mx4 {
  margin-left: 2.4rem !important;
  margin-right: 2.4rem !important; }

.my4 {
  margin-top: 2.4rem !important;
  margin-bottom: 2.4rem !important; }

.relative {
  position: relative !important; }

.absolute {
  position: absolute !important; }

.fixed {
  position: fixed !important; }

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.absoluteCenter-x {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.absoluteCenter-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.absoluteSpread {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.linkOverlay__primary {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.valign-middle {
  vertical-align: middle !important; }

svg {
  fill: currentColor; }
