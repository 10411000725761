$enable-validation-icons: false;

// Colors
$blue: #004459 !default;
$pink: #e83e8c !default;
$red: #cc4053 !default;
$green: #46ba7a !default;
$yellow: #ffad02 !default;
$teal: #00a098 !default;
$cyan: #7ae5c9 !default;

$primary: $teal !default;
$success: $cyan !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;

// Body
//
// Settings for the `<body>` element.
$body-bg: #fcfcfc !default;

// $font-family-sans-serif: Work Sans, -apple-system, BlinkMacSystemFont,
//   'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
//   'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
// $font-family-monospace: Work Sans, SFMono-Regular, Menlo, Monaco, Consolas,
//   'Liberation Mono', 'Courier New', monospace !default;
// $font-family-base: $font-family-sans-serif !default;

// $headings-margin-bottom: 0px;

// $paragraph-margin-bottom: 0px;
