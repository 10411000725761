@import '../../assets/styles/custom';

.loading-spinner {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.loading-spinner .spinner-ticks {
  position: relative;
  height: 32px;
  width: 32px;
}

.loading-spinner .spinner-ticks .spinner-tick:first-child {
  -webkit-transform: rotate(22.5deg);
  transform: rotate(22.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:first-child:before {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(2):before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(3) {
  -webkit-transform: rotate(67.5deg);
  transform: rotate(67.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(3):before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(4):before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(5) {
  -webkit-transform: rotate(112.5deg);
  transform: rotate(112.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(5):before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(6) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(6):before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(7) {
  -webkit-transform: rotate(157.5deg);
  transform: rotate(157.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(7):before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(8) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(8):before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(9) {
  -webkit-transform: rotate(202.5deg);
  transform: rotate(202.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(9):before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(10) {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(10):before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(11) {
  -webkit-transform: rotate(247.5deg);
  transform: rotate(247.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(11):before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(12) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(12):before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(13) {
  -webkit-transform: rotate(292.5deg);
  transform: rotate(292.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(13):before {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(14) {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(14):before {
  -webkit-animation-delay: -1.3s;
  animation-delay: -1.3s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(15) {
  -webkit-transform: rotate(337.5deg);
  transform: rotate(337.5deg);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(15):before {
  -webkit-animation-delay: -1.4s;
  animation-delay: -1.4s;
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(16) {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.loading-spinner .spinner-ticks .spinner-tick:nth-child(16):before {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.loading-spinner .spinner-ticks .spinner-tick {
  position: absolute;
  top: auto;
  right: 50%;
  bottom: auto;
  left: auto;
  width: 1px;
  height: 100%;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.loading-spinner .spinner-ticks .spinner-tick:after,
.loading-spinner .spinner-ticks .spinner-tick:before {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: auto;
  width: 1px;
  height: 4px;
  content: '';
  display: block;
  background: hsla(0, 0%, 100%, 0.2);
}

.loading-spinner .spinner-ticks .spinner-tick:before {
  -webkit-animation: glow-loading 1.6s linear infinite;
  animation: glow-loading 1.6s linear infinite;
  opacity: 1;
  z-index: 1;
  background: $primary;
}

.loading-spinner .spinner-wheel {
  -webkit-animation: spin 1.6s linear infinite;
  animation: spin 1.6s linear infinite;
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: auto;
  left: auto;
  width: auto;
  height: auto;
  z-index: 1;
  margin-top: -10px;
  margin-right: -10px;
  height: 20px;
  width: 20px;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-color: $primary $primary transparent transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 3em;
}

.loading-spinner.secondary .spinner-tick:after {
  background: hsla(0, 0%, 80%, 0.2);
}

.loading-spinner.secondary .spinner-tick:before {
  background: #999;
}

.loading-spinner.secondary .spinner-wheel {
  border-top-color: #999;
  border-right-color: #999;
}

.loading-spinner.medium .spinner-ticks {
  height: 48px;
  width: 48px;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:first-child {
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:first-child:before {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(2):before {
  -webkit-animation-delay: -66.66666666666667ms;
  animation-delay: -66.66666666666667ms;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(3) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(3):before {
  -webkit-animation-delay: -0.13333333333333333s;
  animation-delay: -0.13333333333333333s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(4) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(4):before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(5) {
  -webkit-transform: rotate(75deg);
  transform: rotate(75deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(5):before {
  -webkit-animation-delay: -266.6666666666667ms;
  animation-delay: -266.6666666666667ms;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(6) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(6):before {
  -webkit-animation-delay: -0.3333333333333333s;
  animation-delay: -0.3333333333333333s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(7) {
  -webkit-transform: rotate(105deg);
  transform: rotate(105deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(7):before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(8) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(8):before {
  -webkit-animation-delay: -0.4666666666666667s;
  animation-delay: -0.4666666666666667s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(9) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(9):before {
  -webkit-animation-delay: -0.5333333333333333s;
  animation-delay: -0.5333333333333333s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(10) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(10):before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(11) {
  -webkit-transform: rotate(165deg);
  transform: rotate(165deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(11):before {
  -webkit-animation-delay: -0.6666666666666666s;
  animation-delay: -0.6666666666666666s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(12) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(12):before {
  -webkit-animation-delay: -0.7333333333333334s;
  animation-delay: -0.7333333333333334s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(13) {
  -webkit-transform: rotate(195deg);
  transform: rotate(195deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(13):before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(14) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(14):before {
  -webkit-animation-delay: -0.8666666666666666s;
  animation-delay: -0.8666666666666666s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(15) {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(15):before {
  -webkit-animation-delay: -0.9333333333333333s;
  animation-delay: -0.9333333333333333s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(16) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(16):before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(17) {
  -webkit-transform: rotate(255deg);
  transform: rotate(255deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(17):before {
  -webkit-animation-delay: -1.0666666666666667s;
  animation-delay: -1.0666666666666667s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(18) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(18):before {
  -webkit-animation-delay: -1.1333333333333333s;
  animation-delay: -1.1333333333333333s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(19) {
  -webkit-transform: rotate(285deg);
  transform: rotate(285deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(19):before {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(20) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(20):before {
  -webkit-animation-delay: -1.2666666666666668s;
  animation-delay: -1.2666666666666668s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(21) {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(21):before {
  -webkit-animation-delay: -1.3333333333333333s;
  animation-delay: -1.3333333333333333s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(22) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(22):before {
  -webkit-animation-delay: -1.4s;
  animation-delay: -1.4s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(23) {
  -webkit-transform: rotate(345deg);
  transform: rotate(345deg);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(23):before {
  -webkit-animation-delay: -1.4666666666666668s;
  animation-delay: -1.4666666666666668s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(24) {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.loading-spinner.medium .spinner-ticks .spinner-tick:nth-child(24):before {
  -webkit-animation-delay: -1.5333333333333332s;
  animation-delay: -1.5333333333333332s;
}

.loading-spinner.medium .spinner-ticks .spinner-tick:after,
.loading-spinner.medium .spinner-ticks .spinner-tick:before {
  height: 4px;
}

.loading-spinner.medium .spinner-wheel {
  margin-top: -17px;
  margin-right: -17px;
  height: 34px;
  width: 34px;
}

.loading-spinner.large .spinner-ticks {
  height: 80px;
  width: 80px;
}

.loading-spinner.large .spinner-ticks .spinner-tick:first-child {
  -webkit-transform: rotate(11.25deg);
  transform: rotate(11.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:first-child:before {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(2) {
  -webkit-transform: rotate(22.5deg);
  transform: rotate(22.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(2):before {
  -webkit-animation-delay: -50ms;
  animation-delay: -50ms;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(3) {
  -webkit-transform: rotate(33.75deg);
  transform: rotate(33.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(3):before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(4) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(4):before {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(5) {
  -webkit-transform: rotate(56.25deg);
  transform: rotate(56.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(5):before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(6) {
  -webkit-transform: rotate(67.5deg);
  transform: rotate(67.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(6):before {
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(7) {
  -webkit-transform: rotate(78.75deg);
  transform: rotate(78.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(7):before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(8) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(8):before {
  -webkit-animation-delay: -0.35s;
  animation-delay: -0.35s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(9) {
  -webkit-transform: rotate(101.25deg);
  transform: rotate(101.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(9):before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(10) {
  -webkit-transform: rotate(112.5deg);
  transform: rotate(112.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(10):before {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(11) {
  -webkit-transform: rotate(123.75deg);
  transform: rotate(123.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(11):before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(12) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(12):before {
  -webkit-animation-delay: -0.55s;
  animation-delay: -0.55s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(13) {
  -webkit-transform: rotate(146.25deg);
  transform: rotate(146.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(13):before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(14) {
  -webkit-transform: rotate(157.5deg);
  transform: rotate(157.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(14):before {
  -webkit-animation-delay: -0.65s;
  animation-delay: -0.65s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(15) {
  -webkit-transform: rotate(168.75deg);
  transform: rotate(168.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(15):before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(16) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(16):before {
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(17) {
  -webkit-transform: rotate(191.25deg);
  transform: rotate(191.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(17):before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(18) {
  -webkit-transform: rotate(202.5deg);
  transform: rotate(202.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(18):before {
  -webkit-animation-delay: -0.85s;
  animation-delay: -0.85s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(19) {
  -webkit-transform: rotate(213.75deg);
  transform: rotate(213.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(19):before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(20) {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(20):before {
  -webkit-animation-delay: -0.95s;
  animation-delay: -0.95s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(21) {
  -webkit-transform: rotate(236.25deg);
  transform: rotate(236.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(21):before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(22) {
  -webkit-transform: rotate(247.5deg);
  transform: rotate(247.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(22):before {
  -webkit-animation-delay: -1.05s;
  animation-delay: -1.05s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(23) {
  -webkit-transform: rotate(258.75deg);
  transform: rotate(258.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(23):before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(24) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(24):before {
  -webkit-animation-delay: -1.15s;
  animation-delay: -1.15s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(25) {
  -webkit-transform: rotate(281.25deg);
  transform: rotate(281.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(25):before {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(26) {
  -webkit-transform: rotate(292.5deg);
  transform: rotate(292.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(26):before {
  -webkit-animation-delay: -1.25s;
  animation-delay: -1.25s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(27) {
  -webkit-transform: rotate(303.75deg);
  transform: rotate(303.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(27):before {
  -webkit-animation-delay: -1.3s;
  animation-delay: -1.3s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(28) {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(28):before {
  -webkit-animation-delay: -1.35s;
  animation-delay: -1.35s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(29) {
  -webkit-transform: rotate(326.25deg);
  transform: rotate(326.25deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(29):before {
  -webkit-animation-delay: -1.4s;
  animation-delay: -1.4s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(30) {
  -webkit-transform: rotate(337.5deg);
  transform: rotate(337.5deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(30):before {
  -webkit-animation-delay: -1.45s;
  animation-delay: -1.45s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(31) {
  -webkit-transform: rotate(348.75deg);
  transform: rotate(348.75deg);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(31):before {
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(32) {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.loading-spinner.large .spinner-ticks .spinner-tick:nth-child(32):before {
  -webkit-animation-delay: -1.55s;
  animation-delay: -1.55s;
}

.loading-spinner.large .spinner-ticks .spinner-tick:after,
.loading-spinner.large .spinner-ticks .spinner-tick:before {
  height: 6px;
}

.loading-spinner.large .spinner-wheel {
  margin-top: -31px;
  margin-right: -31px;
  height: 62px;
  width: 62px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes glow-loading {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes glow-loading {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
