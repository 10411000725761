html {
  font-size: 16px;
}

strong,
b {
  font-weight: 600;
}

[data-focus-source='keyboard'] :focus {
  border-color: transparent;
  outline: 2px solid #008248;
}

@import './custom';
@import './flexbox';
@import './spacing';
@import './positioning';
@import './utils';
