.linkOverlay__primary {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.valign-middle {
  vertical-align: middle !important;
}

svg {
  fill: currentColor;
}
