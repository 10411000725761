$spacing0: 0;
$spacing1: 0.4rem;
$spacing2: 0.8rem;
$spacing3: 1.6rem;
$spacing4: 2.4rem;

$spacing-sizes: (
  '0': $spacing0,
  '1': $spacing1,
  '2': $spacing2,
  '3': $spacing3,
  '4': $spacing4,
);

@each $size, $value in $spacing-sizes {
  .p#{$size} {
    padding: $value !important;
  }
  .pt#{$size} {
    padding-top: $value !important;
  }

  .pr#{$size} {
    padding-right: $value !important;
  }

  .pb#{$size} {
    padding-bottom: $value !important;
  }

  .pl#{$size} {
    padding-left: $value !important;
  }

  .px#{$size} {
    padding-left: $value !important;
    padding-right: $value !important;
  }

  .py#{$size} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
}

@each $size, $value in $spacing-sizes {
  .m#{$size} {
    margin: $value !important;
  }
  .mt#{$size} {
    margin-top: $value !important;
  }

  .mr#{$size} {
    margin-right: $value !important;
  }

  .mb#{$size} {
    margin-bottom: $value !important;
  }

  .ml#{$size} {
    margin-left: $value !important;
  }

  .mx#{$size} {
    margin-left: $value !important;
    margin-right: $value !important;
  }

  .my#{$size} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }
}
