.flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.items-start {
  align-items: flex-start !important;
}
.items-end {
  align-items: flex-end !important;
}
.items-center {
  align-items: center !important;
}
.items-baseline {
  align-items: baseline !important;
}
.items-stretch {
  align-items: stretch !important;
}
.self-start {
  align-self: flex-start !important;
}
.self-end {
  align-self: flex-end !important;
}
.self-center {
  align-self: center !important;
}
.self-baseline {
  align-self: baseline !important;
}
.self-stretch {
  align-self: stretch !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around !important;
}
.content-start {
  align-content: flex-start !important;
}
.content-end {
  align-content: flex-end !important;
}
.content-center {
  align-content: center !important;
}
.content-between {
  align-content: space-between !important;
}
.content-around {
  align-content: space-around !important;
}
.content-stretch {
  align-content: stretch !important;
}
.flex-1 {
  flex: 1 !important;
}
.flex-none {
  flex: none !important;
}
.flex-shrink {
  flex-shrink: 1 !important;
}
.flex-shrink-none {
  flex-shrink: 0 !important;
}
.flex-grow {
  flex-grow: 1 !important;
}
.flex-grow-none {
  flex-grow: 0 !important;
}
.flex-basis-none {
  flex-basis: 0% !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-last {
  order: 99999 !important;
}
.order-first {
  order: -1 !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
